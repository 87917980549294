const productsData = [
	{
		title: "French Fries",
		image: "/products/FrenchFries.jpg",
		hoverDescription:
			"Fries are my spirit animal.",
		Description: "French Fries",
		subProducts: [
			{
				title: "Only Salted French Fries",
				image: "/products/FrenchFries.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
			{
				title: "Chaat Masala French Fries",
				image: "/products/FrenchFries.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
			{
				title: "Peri Peri French Fries",
				image: "/products/FrenchFries.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
			{
				title: "Cheese French Fries",
				image: "/products/FrenchFries.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
			{
				title: "HS Special French Fries",
				image: "/products/FrenchFries.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
		],
	},
	{
		title: "Vadapav",
		image: "/products/VadapavImage.jpg",
		hoverDescription: "Ek Dum Mumbai Style",
		Description: "Vadapav",
		subProducts: [
			{
				title: "Apna Mumbai Vada Pav (Hot)",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
			{
				title:
					"Apna Mumbai Vada Pav (Cheese) (Hot)",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
			{
				title: "Chura Vada Pav (Regular) (Hot)",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
			{
				title:
					"Cheese Spicy  Vada Pav (Cheese) (Hot)",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
		],
	},
	{
		title: "Burger",
		image: "/products/Burger.jpg",
		hoverDescription: "Are Yaar Burger Khila Do",
		Description: "Burger",
		subProducts: [
			{
				title: "VadaPav 1",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
			{
				title: "VadaPav 2",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
		],
	},
	{
		title: "Sandwich",
		image: "/products/Sandwich.jpg",
		hoverDescription:
			"Khane Ka Shortcut Apna Sandwich",
		Description: "Sandwich",
		subProducts: [
			{
				title: "VadaPav 1",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
			{
				title: "VadaPav 2",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
		],
	},
	{
		title: "Pizza",
		image: "/products/Pizza.jpg",
		hoverDescription: "Aao Pizza Khilaye",
		Description: "Pizza",
		subProducts: [
			{
				title: "VadaPav 1",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
			{
				title: "VadaPav 2",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
		],
	},
	{
		title: "Garlic Bread",
		image: "/products/GarlicBread.jpg",
		hoverDescription:
			"Pizza ka chhota bhai Garlic Bread",
		Description: "Garlic Bread",
		subProducts: [
			{
				title: "VadaPav 1",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
			{
				title: "VadaPav 2",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
		],
	},
	{
		title: "Maska Bun",
		image: "/products/MaskaBun.jpg",
		hoverDescription:
			"Kisi ko manaye Maska bun ke saath",
		Description: "Maska Bun",
		subProducts: [
			{
				title: "VadaPav 1",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
			{
				title: "VadaPav 2",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
		],
	},
	{
		title: "Chhole Pav",
		image: "/products/ChholePav.jpg",
		hoverDescription: "Chhole Pav",
		Description: "Chhole Pav",
		subProducts: [
			{
				title: "VadaPav 1",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
			{
				title: "VadaPav 2",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
		],
	},

	{
		title: "Tea",
		image: "/products/Tea.jpg",
		hoverDescription: "Tea",
		Description: "Tea",
		subProducts: [
			{
				title: "VadaPav 1",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
			{
				title: "VadaPav 2",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
		],
	},
	{
		title: "Hot Milk",
		image: "/products/Milk.jpg",
		hoverDescription: "Hot Milk",
		Description: "Coffee",
		subProducts: [
			{
				title: "VadaPav 1",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
			{
				title: "VadaPav 2",
				image: "/products/VadapavImage.jpg",
				hoverDescription:
					"Vada is my spirit animal.",
				Description: "Vada",
			},
		],
	},

	// Add more products as needed
]

export default productsData
